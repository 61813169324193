<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-card elevation="4" class="mt-0">
      <v-data-table
        v-if="listSections"
        :headers="headers"
        :items="listSections"
        :items-per-page="10"
        :loading="isLoadingSections"
      >
        <template v-slot:item.status="{ item }">
          <v-chip :color="getColor(item.status)" dark>
            {{ item.status }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
          <v-icon small @click="destroy(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("sections") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-row justify="end">
              <v-dialog v-model="isVisibleDialog" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    {{ $t("add_new_section") }}
                  </v-btn>
                </template>
                <v-form ref="form" v-model="is_valid_form" lazy-validation>
                  <v-card>
                    <v-card-title class="pb-4">
                      <span class="text-h3">{{ $t("sections") }}</span>
                    </v-card-title>
                    <v-card-text class="">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              outlined
                              :label="$t('name_*')"
                              v-model="editedSection.name"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              outlined
                              :label="$t('code_*')"
                              v-model="editedSection.code"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select
                              dense
                              outlined
                              :label="$t('hub_*')"
                              v-model="editedSection.hub_id"
                              :items="listHubs"
                              item-text="name"
                              item-value="id"
                              :loading="isLoadingHubs"
                              :rules="[rules.required]"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select
                              dense
                              outlined
                              :label="$t('status_*')"
                              v-model="editedSection.status"
                              :items="listStatuses"
                              :rules="[rules.required]"
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <small>{{ $t("indicates_required_field") }}</small>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="closeForm()">
                        {{ $t("close") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="saveItem(editedSection)"
                        :disabled="!is_valid_form"
                      >
                        {{ $t("save") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <v-dialog v-model="isVisibleDeleteDialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">{{
                    $t("delete_confirmation_message")
                  }}</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeForm">{{
                      $t("cancel")
                    }}</v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="destroyConfirm(editedSection)"
                      >{{ $t("ok") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],
  async mounted() {
    try {
      await this.$store.dispatch("sections/list");
      await this.$store.dispatch("hubs/list");
    } catch (error) {
      if (error.response?.status == "401") {
        this.$store.dispatch("logout");
      }
      this.$store.dispatch("alerts/error", error.response?.data?.message);
    }
  },

  computed: {
    ...mapGetters({
      isLoadingSections: "sections/isLoadingSections",
      isLoadingHubs: "hubs/isLoadingHubs",

      listSections: "sections/listSections",
      listStatuses: "statuses/listStatuses",
      listHubs: "hubs/listHubs",
    }),
  },
  data() {
    return {
      is_valid_form: true,
      isVisibleDialog: false,
      isVisibleDeleteDialog: false,
      editedSection: {},
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("status"),
          align: "center",
          value: "status",
          width: "15%",
        },
        {
          text: this.$t("hub"),
          align: "center",
          value: "hub.name",
          width: "15%",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
          width: "15%",
        },
      ],
    };
  },
  methods: {
    edit(item) {
      this.isVisibleDialog = true;
      this.editedSection = Object.assign({}, item);
    },

    async saveItem(item) {
      if (this.$refs.form.validate()) {
        try {
          if (item.id) {
            await this.$store.dispatch("sections/update", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("sections/add", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.$refs.form.reset();
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    destroy(item) {
      this.isVisibleDeleteDialog = true;
      this.editedSection = Object.assign({}, item);
    },

    async destroyConfirm(item) {
      try {
        await this.$store.dispatch("sections/destroy", item);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
      this.closeForm();
    },

    closeForm() {
      this.isVisibleDialog = false;
      this.isVisibleDeleteDialog = false;
      this.editedSection = {};
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>
